.ui-sortable tr {     cursor:pointer; }    .ui-sortable tr:hover {     background:rgba(244,251,17,0.45); }

.jp-title {
    display: block !important;
}

#player_footer {
    position: fixed;
    bottom: 0;
}


