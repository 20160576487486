html {
  background-color: #f2f4f8;
  overflow-x: hidden;
}
body {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #788188;
  background-color: transparent;
  -webkit-font-smoothing: antialiased;
  line-height: 1.40;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}
a {
  color: #545a5f;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #303437;
  text-decoration: none;
}
label {
  font-weight: normal;
}
small,
.small {
  font-size: 13px;
}
.badge,
.label {
  font-weight: bold;
}
.badge {
  background-color: #aac4c8;
}
.badge.up {
  position: relative;
  top: -10px;
  padding: 3px 6px;
  margin-left: -10px;
}
.badge-sm {
  font-size: 85%;
  padding: 2px 5px !important;
}
.label-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.badge-white {
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.35);
  padding: 2px 6px;
}
.badge-empty {
  background-color: transparent;
  border: 1px solid rgba(0,0,0,0.15);
  color: inherit;
}
.caret-white {
  border-top-color: #fff;
  border-top-color: rgba(255,255,255,0.65);
}
a:hover .caret-white {
  border-top-color: #fff;
}
.tooltip-inner {
  background-color: rgba(0,0,0,0.9);
  background-color: #5a6a7a;
}
.tooltip.top .tooltip-arrow {
  border-top-color: rgba(0,0,0,0.9);
  border-top-color: #5a6a7a;
}
.tooltip.right .tooltip-arrow {
  border-right-color: rgba(0,0,0,0.9);
  border-right-color: #5a6a7a;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: rgba(0,0,0,0.9);
  border-bottom-color: #5a6a7a;
}
.tooltip.left .tooltip-arrow {
  border-left-color: rgba(0,0,0,0.9);
  border-left-color: #5a6a7a;
}
.thumbnail {
  border-color: #eaeef1;
}
.popover-content {
  font-size: 12px;
  line-height: 1.5;
}
.progress-xs {
  height: 6px;
}
.progress-sm {
  height: 10px;
}
.progress-sm .progress-bar {
  font-size: 10px;
  line-height: 1em;
}
.progress,
.progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.breadcrumb {
  background-color: #fff;
  border: 1px solid #eaeef1;
  padding-left: 10px;
  margin-bottom: 10px;
}
.breadcrumb > li + li:before,
.breadcrumb > .active {
  color: inherit;
}
.accordion-group,
.accordion-inner {
  border-color: #eaeef1;
  border-radius: 2px;
}
.alert {
  font-size: 13px;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
}
.alert .close i {
  font-size: 12px;
  font-weight: normal;
  display: block;
}
.form-control {
  border-color: #cbd5dd;
  border-radius: 2px;
}
.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  border-color: #545ca6;
}
.input-s-sm {
  width: 120px;
}
.input-s {
  width: 200px;
}
.input-s-lg {
  width: 250px;
}
.input-lg {
  height: 45px;
}
.input-group-addon {
  border-color: #cbd5dd;
  background-color: #f1f5f6;
}
.list-group {
  border-radius: 2px;
}
.list-group.no-radius .list-group-item {
  border-radius: 0 !important;
}
.list-group.no-borders .list-group-item {
  border: none;
}
.list-group.no-border .list-group-item {
  border-width: 1px 0;
}
.list-group.no-bg .list-group-item {
  background-color: transparent;
}
.list-group-item {
  border-color: #eaeef1;
  padding-right: 15px;
}
a.list-group-item:hover,
a.list-group-item:focus {
  background-color: #eef3f4;
}
.list-group-item.media {
  margin-top: 0;
}
.list-group-item.active {
  color: #fff;
  border-color: #4cb6cb !important;
  background-color: #4cb6cb !important;
}
.list-group-item.active .text-muted {
  color: #c3e6ed;
}
.list-group-item.active a {
  color: #fff;
}
.list-group-alt .list-group-item:nth-child(2n+2) {
  background-color: rgba(0,0,0,0.02) !important;
}
.list-group-lg .list-group-item {
  padding-top: 15px;
  padding-bottom: 15px;
}
.list-group-sp .list-group-item {
  margin-bottom: 5px;
  border-radius: 3px;
}
.list-group-item > .badge {
  margin-right: 0;
}
.list-group-item > .fa-chevron-right {
  float: right;
  margin-top: 4px;
  margin-right: -5px;
}
.list-group-item > .fa-chevron-right + .badge {
  margin-right: 5px;
}
.nav-pills.no-radius > li > a {
  border-radius: 0;
}
.nav-pills > li.active > a {
  color: #fff !important;
  background-color: #4cb6cb;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #edf2f3;
}
.nav.nav-sm > li > a {
  padding: 6px 8px;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #edf2f3;
}
.nav-tabs {
  border-color: #eaeef1;
}
.nav-tabs > li > a {
  border-radius: 2px 2px 0 0;
  border-bottom-color: #eaeef1 !important;
}
.nav-tabs > li.active > a {
  border-color: #eaeef1 !important;
  border-bottom-color: #fff !important;
}
.pagination > li > a {
  border-color: #eaeef1;
}
.pagination > li > a:hover,
.pagination > li > a:focus {
  border-color: #eaeef1;
  background-color: #e8eff0;
}
.panel {
  border-radius: 2px;
}
.panel.panel-default {
  border-color: #eaeef1;
}
.panel.panel-default > .panel-heading,
.panel.panel-default > .panel-footer {
  border-color: #eaeef1;
}
.panel .list-group-item {
  border-color: #f3f5f7;
}
.panel.no-borders {
  border-width: 0;
}
.panel.no-borders .panel-heading,
.panel.no-borders .panel-footer {
  border-width: 0;
}
.panel .table td,
.panel .table th {
  padding: 8px 15px;
  border-top: 1px solid #eaeef1;
}
.panel .table thead > tr > th {
  border-bottom: 1px solid #eaeef1;
}
.panel .table-striped > tbody > tr:nth-child(odd) > td,
.panel .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f7fafa;
}
.panel .table-striped > thead th {
  background-color: #f7fafa;
  border-right: 1px solid #eaeef1;
}
.panel .table-striped > thead th:last-child {
  border-right: none;
}
.panel-heading {
  border-radius: 2px 2px 0 0;
}
.panel-default .panel-heading {
  background-color: #f7fafa;
}
.panel-heading.no-border {
  margin: -1px -1px 0 -1px;
  border: none;
}
.panel-heading .nav {
  margin: -10px -15px;
}
.panel-heading .nav-tabs {
  margin: -11px -16px;
}
.panel-heading .nav-tabs.nav-justified {
  width: auto;
}
.panel-heading .nav-tabs > li > a {
  margin: 0;
  padding-top: 9px;
}
.panel-heading .list-group {
  background: transparent;
}
.panel-footer {
  border-color: #eaeef1;
  border-radius: 0 0 2px 2px;
  background-color: #f7fafa;
}
.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #eaedef;
}
.open {
  z-index: 1050;
  position: relative;
}
.dropdown-menu {
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  border: 1px solid #ddd;
  border: 1px solid rgba(0,0,0,0.1);
}
.dropdown-menu.pull-left {
  left: 100%;
}
.dropdown-menu > .panel {
  border: none;
  margin: -5px 0;
}
.dropdown-menu > li > a {
  padding: 5px 15px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-image: none;
  filter: none;
  background-color: #e8eff0 !important;
  color: #303437;
}
.dropdown-header {
  padding: 5px 15px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  background-color: #e8eff0 !important;
  color: #788188;
}
.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu:focus > .dropdown-menu {
  display: block;
}
.dropdown-submenu.pull-left {
  float: none !important;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropdown-submenu .dropdown-menu {
  left: 100%;
  top: 0;
  margin-top: -6px;
  margin-left: -1px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
}
.dropdown-select > li input {
  position: absolute;
  left: -9999em;
}
.carousel-control {
  width: 40px;
  color: #999;
  text-shadow: none;
}
.carousel-control:hover,
.carousel-control:focus {
  color: #ccc;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-control.left,
.carousel-control.right {
  background-image: none;
  filter: none;
}
.carousel-control i {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}
.carousel-indicators.out {
  bottom: -5px;
}
.carousel-indicators li {
  -webkit-transition: background-color .25s;
  transition: background-color .25s;
  background: #ddd;
  background-color: rgba(0,0,0,0.2);
  border: none;
}
.carousel-indicators .active {
  background: #f0f0f0;
  background-color: rgba(200,200,200,0.2);
  width: 10px;
  height: 10px;
  margin: 1px;
}
.carousel.carousel-fade .item {
  -webkit-transition: opacity .25s;
  transition: opacity .25s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
.carousel.carousel-fade .active {
  opacity: 1;
  filter: alpha(opacity=1);
}
.carousel.carousel-fade .active.left,
.carousel.carousel-fade .active.right {
  left: 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}
.carousel.carousel-fade .next,
.carousel.carousel-fade .prev {
  left: 0;
  z-index: 1;
}
.carousel.carousel-fade .carousel-control {
  z-index: 3;
}
.col-lg-2-4 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-0 {
  clear: left;
}
.row.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter [class*="col"] {
  padding: 0;
}
.modal-backdrop {
  background-color: #5a6a7a;
}
.modal-backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.modal-over {
  width: 100%;
  height: 100%;
  position: relative;
  background: #5a6a7a;
}
.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
}
.modal-content {
  -webkit-box-shadow: 0 2px 10px rgba(0,0,0,0.25);
  box-shadow: 0 2px 10px rgba(0,0,0,0.25);
}
.icon-muted {
  color: #ccc;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: transparent;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed !important;
}
.navbar-fixed-top + * {
  padding-top: 50px;
}
.navbar-fixed-top.header-md + * {
  padding-top: 60px;
}
.header,
.footer {
  min-height: 50px;
  padding: 0 15px;
}
.header > p,
.footer > p {
  margin-top: 15px;
  display: inline-block;
}
.header > .btn,
.header > .btn-group,
.header > .btn-toolbar,
.footer > .btn,
.footer > .btn-group,
.footer > .btn-toolbar {
  margin-top: 10px;
}
.header > .btn-lg,
.footer > .btn-lg {
  margin-top: 0;
}
.header .nav-tabs,
.footer .nav-tabs {
  border: none;
  margin-left: -15px;
  margin-right: -15px;
}
.header .nav-tabs > li a,
.footer .nav-tabs > li a {
  border: none !important;
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
}
.header .nav-tabs > li a:hover,
.header .nav-tabs > li a:focus,
.footer .nav-tabs > li a:hover,
.footer .nav-tabs > li a:focus {
  background-color: transparent;
}
.header .nav-tabs > li.active a,
.footer .nav-tabs > li.active a {
  color: #788188;
}
.header .nav-tabs > li.active a,
.header .nav-tabs > li.active a:hover,
.footer .nav-tabs > li.active a,
.footer .nav-tabs > li.active a:hover {
  background-color: #f2f4f8;
}
.header .nav-tabs.nav-white > li.active a,
.header .nav-tabs.nav-white > li.active a:hover,
.footer .nav-tabs.nav-white > li.active a,
.footer .nav-tabs.nav-white > li.active a:hover {
  background-color: #fff;
}
.header.navbar,
.footer.navbar {
  border-radius: 0;
  border: none;
  margin-bottom: 0;
  padding: 0;
  position: relative;
  z-index: 1000;
}
body.container {
  padding: 0;
}
@media (orientation: landscape) {
  html.ios7.ipad > body {
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  body.container {
    -webkit-box-shadow: 0 3px 60px rgba(0,0,0,0.3);
    box-shadow: 0 3px 60px rgba(0,0,0,0.3);
    border-left: 1px solid #cbd5dd;
    border-right: 1px solid #cbd5dd;
  }
  .app,
  .app body {
    height: 100%;
    overflow: hidden;
  }
  .app .hbox.stretch {
    height: 100%;
  }
  .app .vbox > section,
  .app .vbox > footer {
    position: absolute;
  }
  .app .vbox.flex > section > section {
    overflow: auto;
  }
  .hbox {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
  }
  .hbox > aside,
  .hbox > section {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    float: none;
  }
  .hbox > aside.show,
  .hbox > aside.hidden-sm,
  .hbox > section.show,
  .hbox > section.hidden-sm {
    display: table-cell !important;
  }
  .vbox {
    display: table;
    border-spacing: 0;
    position: relative;
    height: 100%;
    width: 100%;
  }
  .vbox > section,
  .vbox > footer {
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .vbox > header ~ section {
    top: 50px;
  }
  .vbox > header.header-md ~ section {
    top: 60px;
  }
  .vbox > section.w-f {
    bottom: 50px;
  }
  .vbox > section.w-f-md {
    bottom: 60px;
  }
  .vbox > footer {
    top: auto;
    z-index: 1000;
  }
  .vbox.flex > header,
  .vbox.flex > section,
  .vbox.flex > footer {
    position: inherit;
  }
  .vbox.flex > section {
    display: table-row;
    height: 100%;
  }
  .vbox.flex > section > section {
    position: relative;
    height: 100%;
    -webkit-overflow-scrolling: touch;
  }
  .ie .vbox.flex > section > section {
    display: table-cell;
  }
  .vbox.flex > section > section > section {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .aside-xs {
    width: 60px;
  }
  .aside-sm {
    width: 150px;
  }
  .aside {
    width: 200px;
  }
  .aside-md {
    width: 240px;
  }
  .aside-lg {
    width: 300px;
  }
  .aside-xl {
    width: 360px;
  }
  .aside-xxl {
    width: 480px;
  }
  .header-md {
    min-height: 60px;
  }
  .header-md .navbar-form {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .footer-md {
    min-height: 60px;
  }
  .scrollable {
    -webkit-overflow-scrolling: touch;
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(50,50,50,0.25);
    border: 2px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(50,50,50,0.5);
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(50,50,50,0.05);
  }
}
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
}
.no-touch .scrollable.hover {
  overflow-y: hidden;
}
.no-touch .scrollable.hover:hover {
  overflow: visible;
  overflow-y: auto;
}
.no-touch ::-webkit-scrollbar-button {
  width: 10px;
  height: 6px;
  background-color: rgba(50,50,50,0.05);
}
.slimScrollBar {
  border-radius: 5px;
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box !important;
}
@media print {
  html,
  body,
  .hbox,
  .vbox {
    height: auto;
  }
  .vbox > section,
  .vbox > footer {
    position: relative;
  }
}
.navbar-header {
  position: relative;
}
.navbar-header > .btn {
  position: absolute;
  font-size: 1.3em;
  padding: 9px 16px;
  line-height: 30px;
  left: 0;
  text-decoration: none !important;
}
.navbar-header .navbar-brand + .btn {
  right: 0;
  top: 0;
  left: auto;
}
.navbar-brand {
  float: none;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  height: auto;
  line-height: 50px;
  display: inline-block;
  padding: 0 20px;
}
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-brand img {
  max-height: 20px;
  margin-top: -4px;
  vertical-align: middle;
}
.nav-primary li > a > i {
  margin: -10px -10px;
  line-height: 38px;
  width: 38px;
  float: left;
  margin-right: 5px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.nav-primary li > a > i:before {
  position: relative;
  z-index: 2;
}
.nav-primary ul.nav > li > a {
  padding: 9px 20px;
  position: relative;
  -webkit-transition: background-color .2s ease-in-out 0s;
  transition: background-color .2s ease-in-out 0s;
}
.no-borders .nav-primary ul.nav > li > a {
  border-width: 0 !important;
}
.nav-primary ul.nav > li > a > .badge {
  font-size: 11px;
  padding: 2px 5px 2px 4px;
  margin-top: 2px;
}
.nav-primary ul.nav > li > a > .text-muted {
  margin: 0 3px;
}
.nav-primary ul.nav > li > a.active .text {
  display: none;
}
.nav-primary ul.nav > li > a.active .text-active {
  display: inline-block !important;
}
.nav-primary ul.nav > li li a {
  font-weight: normal;
  text-transform: none;
}
.nav-primary ul.nav > li.active > ul {
  display: block;
}
.nav-primary ul.nav ul {
  display: none;
}
@media (min-width: 768px) {
  .visible-nav-xs {
    display: none;
  }
  .nav-xs {
    width: 70px;
  }
  .nav-xs .slimScrollDiv,
  .nav-xs .slim-scroll {
    overflow: visible !important;
  }
  .nav-xs .slimScrollBar,
  .nav-xs .slimScrollRail {
    display: none !important;
  }
  .nav-xs .scrollable {
    overflow: visible;
  }
  .nav-xs .nav-primary > ul > li > a {
    position: relative;
    padding: 0;
    font-size: 11px;
    text-align: center;
    height: 50px;
    overflow-y: hidden;
    border: none;
  }
  .nav-xs .nav-primary > ul > li > a span {
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    width: 70px;
    padding: 0 5px;
  }
  .nav-xs .nav-primary > ul > li > a span.pull-right {
    display: none !important;
  }
  .nav-xs .nav-primary > ul > li > a i {
    width: auto;
    float: none;
    display: block;
    font-size: 16px;
    margin: 0;
    line-height: 50px;
    border: none !important;
    -webkit-transition: margin-top 0.2s;
    transition: margin-top 0.2s;
  }
  .nav-xs .nav-primary > ul > li > a i b {
    left: 0 !important;
  }
  .nav-xs .nav-primary > ul > li > a .badge {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 3;
  }
  .nav-xs .nav-primary > ul > li:hover > a i,
  .nav-xs .nav-primary > ul > li:focus > a i,
  .nav-xs .nav-primary > ul > li:active > a i,
  .nav-xs .nav-primary > ul > li.active > a i {
    margin-top: -50px;
  }
  .nav-xs .nav-primary > ul ul {
    display: none !important;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1050;
    width: 220px;
    -webkit-box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  }
  .nav-xs .nav-primary li:hover > ul,
  .nav-xs .nav-primary li:focus > ul,
  .nav-xs .nav-primary li:active > ul {
    display: block !important;
  }
  .nav-xs.nav-xs-right .nav-primary > ul ul {
    left: auto;
    right: 100%;
  }
  .nav-xs > .vbox > .header,
  .nav-xs > .vbox > .footer {
    padding: 0 20px;
  }
  .nav-xs .hidden-nav-xs {
    display: none;
  }
  .nav-xs .visible-nav-xs {
    display: inherit;
  }
  .nav-xs .text-center-nav-xs {
    text-align: center;
  }
  .nav-xs .nav-user {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-xs .nav-user .avatar {
    float: none !important;
    margin-right: 0;
  }
  .nav-xs .nav-user .dropdown > a {
    display: block;
    text-align: center;
  }
  .nav-xs .navbar-header {
    float: none;
  }
  .nav-xs .navbar-brand {
    display: block;
    padding: 0;
  }
  .nav-xs .navbar-brand img {
    margin-right: 0;
  }
  .nav-xs .navbar {
    padding: 0;
  }
  .header-md .navbar-brand {
    line-height: 60px;
  }
  .header-md .navbar-brand img {
    max-height: 30px;
  }
  .header-md .navbar-nav > li > a {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .navbar-fixed-top-xs {
    position: fixed !important;
    left: 0;
    width: 100%;
    z-index: 1100;
  }
  .navbar-fixed-top-xs + * {
    padding-top: 50px !important;
  }
  .nav-bar-fixed-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1100;
  }
  html,
  body {
    overflow-x: hidden;
    min-height: 100%;
  }
  .open,
  .open body {
    height: 100%;
  }
  .nav-primary .dropdown-menu {
    position: relative;
    float: none;
    left: 0;
    margin-left: 0;
    padding: 0;
  }
  .nav-primary .dropdown-menu a {
    padding: 15px;
    border-bottom: 1px solid #eee;
  }
  .nav-primary .dropdown-menu li:last-child a {
    border-bottom: none;
  }
  .navbar-header {
    text-align: center;
  }
  .nav-user {
    margin: 0;
    padding: 15px;
  }
  .nav-user.open {
    display: inherit !important;
  }
  .nav-user .dropdown-menu {
    display: block;
    position: static;
    float: none;
  }
  .nav-user .dropdown > a {
    display: block;
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
  }
  .nav-user .avatar {
    width: 160px !important;
    float: none !important;
    display: block;
    margin: 20px auto;
    padding: 5px;
    background-color: rgba(255,255,255,0.1);
    position: relative;
  }
  .nav-user .avatar:before {
    content: "";
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    top: 5px;
    border: 4px solid #fff;
    border-radius: 500px;
  }
  .nav-off-screen {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0px;
    bottom: 0;
    width: 75%;
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .nav-off-screen .nav-primary {
    display: block !important;
  }
  .nav-off-screen .navbar-fixed-top-xs {
    width: 75%;
  }
  .nav-off-screen.push-right .navbar-fixed-top-xs {
    left: 25%;
  }
  .nav-off-screen.push-right {
    left: auto;
    right: 0;
  }
  .nav-off-screen.push-right + * {
    -webkit-transform: translate3d(-75%,0px,0px);
    transform: translate3d(-75%,0px,0px);
  }
  .nav-off-screen + * {
    background-color: #f2f4f8;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    -moz-transition: -moz-transform 0.2s ease-in-out;
    -o-transition: -o-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate3d(0px,0px,0px);
    transform: translate3d(0px,0px,0px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(75%,0px,0px);
    transform: translate3d(75%,0px,0px);
    overflow: hidden;
    position: absolute;
    width: 100%;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .nav-off-screen + * .nav-off-screen-block {
    display: block !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1950;
  }
  .navbar + section .nav-off-screen {
    top: 50px;
  }
  .navbar + section .nav-off-screen + * {
    top: 50px;
  }
  .slimScrollDiv,
  .slim-scroll {
    overflow: visible !important;
    height: auto !important;
  }
  .slimScrollBar,
  .slimScrollRail {
    display: none !important;
  }
}
.arrow {
  border-width: 8px;
  z-index: 10;
}
.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.arrow:after {
  border-width: 7px;
  content: "";
}
.arrow.top {
  left: 50%;
  margin-left: -8px;
  border-top-width: 0;
  border-bottom-color: #eee;
  border-bottom-color: rgba(0,0,0,0.1);
  top: -8px;
}
.arrow.top:after {
  content: " ";
  top: 1px;
  margin-left: -7px;
  border-top-width: 0;
  border-bottom-color: #fff;
}
.arrow.right {
  top: 50%;
  right: -8px;
  margin-top: -8px;
  border-right-width: 0;
  border-left-color: #eee;
  border-left-color: rgba(0,0,0,0.1);
}
.arrow.right:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -7px;
}
.arrow.bottom {
  left: 50%;
  margin-left: -8px;
  border-bottom-width: 0;
  border-top-color: #eee;
  border-top-color: rgba(0,0,0,0.1);
  bottom: -8px;
}
.arrow.bottom:after {
  content: " ";
  bottom: 1px;
  margin-left: -7px;
  border-bottom-width: 0;
  border-top-color: #fff;
}
.arrow.left {
  top: 50%;
  left: -8px;
  margin-top: -8px;
  border-left-width: 0;
  border-right-color: #eee;
  border-right-color: rgba(0,0,0,0.1);
}
.arrow.left:after {
  content: " ";
  left: 1px;
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -7px;
}
.btn-link {
  color: #788188;
}
.btn-link.active {
  webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default {
  color: #788188 !important;
  background-color: #fff;
  border-color: #dbe2e7;
  border-bottom-color: #d5dde3;
  -webkit-box-shadow: 0 1px 1px rgba(90,90,90,0.1);
  box-shadow: 0 1px 1px rgba(90,90,90,0.1);
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #788188 !important;
  background-color: #f2f2f2;
  border-color: #c2ced6;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #dbe2e7;
}
.btn-default.btn-bg {
  border-color: rgba(0,0,0,0.1);
  background-clip: padding-box;
}
.btn-primary {
  color: #fff !important;
  background-color: #545ca6;
  border-color: #545ca6;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff !important;
  background-color: #4b5395;
  border-color: #464d8b;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #545ca6;
  border-color: #545ca6;
}
.btn-success {
  color: #fff !important;
  background-color: #1ab667;
  border-color: #1ab667;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff !important;
  background-color: #17a05a;
  border-color: #159253;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
  background-color: #1ab667;
  border-color: #1ab667;
}
.btn-info {
  color: #fff !important;
  background-color: #4cb6cb;
  border-color: #4cb6cb;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff !important;
  background-color: #39adc4;
  border-color: #36a3b9;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
  background-color: #4cb6cb;
  border-color: #4cb6cb;
}
.btn-warning {
  color: #fff !important;
  background-color: #fad733;
  border-color: #fad733;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff !important;
  background-color: #f9d21a;
  border-color: #f9cf0b;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
  background-color: #fad733;
  border-color: #fad733;
}
.btn-danger {
  color: #fff !important;
  background-color: #f05050;
  border-color: #f05050;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff !important;
  background-color: #ee3939;
  border-color: #ed2a2a;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
  background-color: #f05050;
  border-color: #f05050;
}
.btn-dark {
  color: #fff !important;
  background-color: #5a6a7a;
  border-color: #5a6a7a;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  color: #fff !important;
  background-color: #4f5d6b;
  border-color: #495663;
}
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled,
.btn-dark.disabled:hover,
.btn-dark.disabled:focus,
.btn-dark.disabled:active,
.btn-dark.disabled.active,
.btn-dark[disabled],
.btn-dark[disabled]:hover,
.btn-dark[disabled]:focus,
.btn-dark[disabled]:active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark,
fieldset[disabled] .btn-dark:hover,
fieldset[disabled] .btn-dark:focus,
fieldset[disabled] .btn-dark:active,
fieldset[disabled] .btn-dark.active {
  background-color: #5a6a7a;
  border-color: #5a6a7a;
}
.btn {
  font-weight: 500;
  border-radius: 2px;
}
.btn-icon {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 34px;
  text-align: center;
}
.btn-icon.b-2x {
  width: 36px;
}
.btn-icon.btn-sm {
  width: 30px;
}
.btn-icon.btn-sm.b-2x {
  width: 32px;
}
.btn-icon.btn-lg {
  width: 45px;
}
.btn-icon.btn-lg.b-2x {
  width: 47px;
}
.btn-group-justified {
  border-collapse: separate;
}
.btn-rounded {
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-rounded.btn-lg {
  padding-left: 25px;
  padding-right: 25px;
}
.btn > i.pull-left,
.btn > i.pull-right {
  line-height: 1.428571429;
}
.btn-block {
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
}
.btn-inactive {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.chat-item:before,
.chat-item:after {
  content: " ";
  display: table;
}
.chat-item:after {
  clear: both;
}
.chat-item .arrow {
  top: 20px;
}
.chat-item .arrow.right:after {
  border-left-color: #e8eff0;
}
.chat-item .chat-body {
  position: relative;
  margin-left: 50px;
  min-height: 30px;
}
.chat-item .chat-body .panel {
  margin: 0 -1px;
}
.chat-item.right .chat-body {
  margin-left: 0;
  margin-right: 50px;
}
.chat-item+.chat-item {
  margin-top: 15px;
}
.comment-list {
  position: relative;
}
.comment-list .comment-item {
  margin-top: 0;
  position: relative;
}
.comment-list .comment-item > .thumb-sm {
  width: 36px;
}
.comment-list .comment-item .arrow.left {
  top: 20px;
  left: 39px;
}
.comment-list .comment-item .comment-body {
  margin-left: 46px;
}
.comment-list .comment-item .panel-body {
  padding: 10px 15px;
}
.comment-list .comment-item .panel-heading,
.comment-list .comment-item .panel-footer {
  position: relative;
  font-size: 12px;
  background-color: #fff;
}
.comment-list .comment-reply {
  margin-left: 46px;
}
.comment-list:before {
  position: absolute;
  top: 0;
  bottom: 35px;
  left: 18px;
  width: 1px;
  background: #e0e4e8;
  content: '';
}
.timeline {
  display: table;
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  position: relative;
  border-collapse: collapse;
}
.timeline:before {
  content: "";
  width: 1px;
  margin-left: -1px;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 30px;
  background-color: #ddd;
  z-index: 0;
}
.timeline .timeline-date {
  position: absolute;
  width: 150px;
  left: -200px;
  top: 50%;
  margin-top: -9px;
  text-align: right;
}
.timeline .timeline-icon {
  position: absolute;
  left: -41px;
  top: -2px;
  top: 50%;
  margin-top: -15px;
}
.timeline .time-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block !important;
  z-index: 10;
  border-radius: 20px;
  text-align: center;
}
.timeline .time-icon:before {
  font-size: 14px;
  margin-top: 5px;
}
.timeline-item {
  display: table-row;
}
.timeline-item:before,
.timeline-item.alt:after {
  content: "";
  display: block;
  width: 50%;
}
.timeline-item.alt {
  text-align: right;
}
.timeline-item.alt:before {
  display: none;
}
.timeline-item.alt .panel {
  margin-right: 25px;
  margin-left: 0;
}
.timeline-item.alt .timeline-date {
  left: auto;
  right: -200px;
  text-align: left;
}
.timeline-item.alt .timeline-icon {
  left: auto;
  right: -41px;
}
.timeline-item.active {
  display: table-caption;
  text-align: center;
}
.timeline-item.active:before {
  width: 1%;
}
.timeline-item.active .timeline-caption {
  display: inline-block;
  width: auto;
}
.timeline-item.active .timeline-caption h5 span {
  color: #fff;
}
.timeline-item.active .panel {
  margin-left: 0;
}
.timeline-item.active .timeline-date,
.timeline-item.active .timeline-icon {
  position: static;
  margin-bottom: 10px;
  display: inline-block;
  width: auto;
}
.timeline-caption {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.timeline-caption .panel {
  position: relative;
  margin-left: 25px;
  text-align: left;
}
.timeline-caption h5 {
  margin: 0;
}
.timeline-caption h5 span {
  display: block;
  color: #999;
  margin-bottom: 4px;
  font-size: 12px;
}
.timeline-caption p {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 10px;
}
.timeline-footer {
  display: table-row;
}
.timeline-footer a {
  display: table-cell;
  text-align: right;
}
.timeline-footer .time-icon {
  margin-right: -15px;
  z-index: 5;
}
.post-item {
  border-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.15);
  margin-bottom: 15px;
}
.post-item .post-title {
  margin-top: 0;
}
.post-item .post-media {
  text-align: center;
}
.post-item .post-media img {
  border-radius: 3px 3px 0 0;
}
.i-fw {
  width: 1.2857142857143em;
  text-align: center;
}
.i-lg {
  font-size: 1.3333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.i-md {
  font-size: 1.2em;
}
.i-sm {
  font-size: 0.75em;
}
.i-1x {
  font-size: 1em;
}
.i-2x {
  font-size: 2em;
}
.i-3x {
  font-size: 3em;
}
.i-4x {
  font-size: 4em;
}
.i-5x {
  font-size: 5em;
}
.i-s {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.i-s > i {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: inherit;
}
.i-s-2x {
  width: 2em;
  height: 2em;
  font-size: 2em;
  line-height: 2em;
}
.i-s-2x .i-s-base {
  font-size: 2em;
}
.i-s-3x {
  width: 2.5em;
  height: 2.5em;
  font-size: 2.5em;
  line-height: 2.5em;
}
.i-s-3x .i-s-base {
  font-size: 2.5em;
}
.i-s-4x {
  width: 3em;
  height: 3em;
  font-size: 3em;
  line-height: 3em;
}
.i-s-4x .i-s-base {
  font-size: 3em;
}
.i-s-5x {
  width: 3.5em;
  height: 3.5em;
  font-size: 3.5em;
  line-height: 3.5em;
}
.i-s-5x .i-s-base {
  font-size: 3.5em;
}
.switch {
  cursor: pointer;
  position: relative;
}
.switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}
.switch input:checked + span {
  background-color: #1ab667;
}
.switch input:checked + span:after {
  left: 31px;
}
.switch span {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #eee;
  border-color: rgba(0,0,0,0.1);
  display: inline-block;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
.switch span:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 26px;
  top: 1px;
  bottom: 1px;
  border-radius: 30px;
  -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  box-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}
.nav-docs > ul > li > a {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.dropfile {
  border: 2px dashed #e0e4e8;
  text-align: center;
  min-height: 20px;
}
.dropfile.hover {
  border-color: #aac3cc;
}
.dropfile small {
  margin: 50px 0;
  display: block;
}
.portlet {
  min-height: 30px;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.easypiechart {
  position: relative;
  text-align: center;
}
.easypiechart > div {
  position: relative;
  z-index: 1;
}
.easypiechart > div .text {
  position: absolute;
  width: 100%;
  top: 60%;
  line-height: 1;
}
.easypiechart > div img {
  margin-top: -4px;
}
.easypiechart canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.flot-legend .legend > div {
  display: none;
}
.flot-legend .legend .legendColorBox > div {
  border: none !important;
  margin: 5px;
}
.flot-legend .legend .legendColorBox > div > div {
  border-radius: 10px;
}
.doc-buttons .btn {
  margin-bottom: 5px;
}
.list-icon i {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  -webkit-transition: font-size .2s;
  transition: font-size .2s;
}
.list-icon div {
  line-height: 40px;
  white-space: nowrap;
}
.list-icon div:hover i {
  font-size: 26px;
}
.th-sortable {
  cursor: pointer;
}
.th-sortable .th-sort {
  float: right;
  position: relative;
}
.th-sort i {
  position: relative;
  z-index: 1;
}
.th-sort .fa-sort {
  position: absolute;
  left: 0;
  top: 3px;
  color: #bac3cc;
  z-index: 0;
}
.th-sortable.active .text {
  display: none !important;
}
.th-sortable.active .text-active {
  display: inline-block !important;
}
.sortable-placeholder {
  list-style: none;
  border: 1px dashed #CCC;
  min-height: 50px;
  margin-bottom: 5px;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.parsley-error-list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 6px;
  font-size: 12px;
}
.parsley-error {
  border-color: #ff5f5f !important;
}
.datepicker td.active,
.datepicker td.active:hover,
.datepicker td.active:hover.active,
.datepicker td.active.active {
  background: #545ca6;
}
#flotTip {
  padding: 3px 5px;
  background-color: #000;
  z-index: 100;
  color: #fff;
  opacity: .7;
  filter: alpha(opacity=70);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.bg-gradient {
  background-image: -webkit-gradient(linear,left 0,left 100%,from(rgba(40,50,60,0)),to(rgba(40,50,60,0.05)));
  background-image: -webkit-linear-gradient(top,rgba(40,50,60,0),0,rgba(40,50,60,0.05),100%);
  background-image: -moz-linear-gradient(top,rgba(40,50,60,0) 0,rgba(40,50,60,0.05) 100%);
  background-image: linear-gradient(to bottom,rgba(40,50,60,0) 0,rgba(40,50,60,0.05) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#0c28323c', GradientType=0);
  filter: none;
}
.bg-light {
  background-color: #e8eff0;
  color: #788188;
}
.bg-light.lt,
.bg-light .lt {
  background-color: #edf2f3;
}
.bg-light.lter,
.bg-light .lter {
  background-color: #f2f5f5;
}
.bg-light.dk,
.bg-light .dk {
  background-color: #e0eaec;
}
.bg-light.dker,
.bg-light .dker {
  background-color: #d7e5e8;
}
.bg-light.bg,
.bg-light .bg {
  background-color: #e8eff0;
}
.bg-dark {
  background-color: #5a6a7a;
  color: #c9d0d7;
}
.bg-dark.lt,
.bg-dark .lt {
  background-color: #687786;
}
.bg-dark.lter,
.bg-dark .lter {
  background-color: #778490;
}
.bg-dark.dk,
.bg-dark .dk {
  background-color: #4d5d6e;
}
.bg-dark.dker,
.bg-dark .dker {
  background-color: #405161;
}
.bg-dark.bg,
.bg-dark .bg {
  background-color: #5a6a7a;
}
.bg-dark a {
  color: #e6eaed;
}
.bg-dark a:hover {
  color: #fff;
}
.bg-dark a.list-group-item:hover,
.bg-dark a.list-group-item:focus {
  background-color: inherit;
}
.bg-dark .nav > li:hover > a,
.bg-dark .nav > li:focus > a,
.bg-dark .nav > li:active > a,
.bg-dark .nav > li.active > a {
  color: #fff;
  background-color: #62778c;
}
.bg-dark .nav > li > a {
  color: #d8dde2;
}
.bg-dark .nav > li > a:hover,
.bg-dark .nav > li > a:focus {
  background-color: #62778c;
}
.bg-dark .nav .open > a {
  background-color: #62778c;
}
.bg-dark .caret {
  border-top-color: #c9d0d7;
  border-bottom-color: #c9d0d7;
}
.bg-dark.navbar .nav > li.active > a {
  color: #fff;
  background-color: #4d5d6e;
}
.bg-dark .open > a,
.bg-dark .open > a:hover,
.bg-dark .open > a:focus {
  color: #fff;
}
.bg-dark .text-muted {
  color: #acb7c1 !important;
}
.bg-dark .text-lt {
  color: #ffffff !important;
}
.bg-dark .icon-muted {
  color: #acb7c1 !important;
}
.bg-dark.auto .list-group-item,
.bg-dark .auto .list-group-item {
  border-color: #4f5d6b !important;
  background-color: transparent;
}
.bg-dark.auto .list-group-item:hover,
.bg-dark.auto .list-group-item:focus,
.bg-dark.auto .list-group-item:active,
.bg-dark.auto .list-group-item.active,
.bg-dark .auto .list-group-item:hover,
.bg-dark .auto .list-group-item:focus,
.bg-dark .auto .list-group-item:active,
.bg-dark .auto .list-group-item.active {
  background-color: #4d5d6e !important;
}
.bg-black {
  background-color: #232c32;
  color: #7d94a4;
}
.bg-black.lt,
.bg-black .lt {
  background-color: #2f3940;
}
.bg-black.lter,
.bg-black .lter {
  background-color: #3b464d;
}
.bg-black.dk,
.bg-black .dk {
  background-color: #181f24;
}
.bg-black.dker,
.bg-black .dker {
  background-color: #0d1215;
}
.bg-black.bg,
.bg-black .bg {
  background-color: #232c32;
}
.bg-black a {
  color: #9badb9;
}
.bg-black a:hover {
  color: #fff;
}
.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
  background-color: inherit;
}
.bg-black .nav > li:hover > a,
.bg-black .nav > li:focus > a,
.bg-black .nav > li:active > a,
.bg-black .nav > li.active > a {
  color: #fff;
  background-color: #2c3942;
}
.bg-black .nav > li > a {
  color: #8ca1af;
}
.bg-black .nav > li > a:hover,
.bg-black .nav > li > a:focus {
  background-color: #2c3942;
}
.bg-black .nav .open > a {
  background-color: #2c3942;
}
.bg-black .caret {
  border-top-color: #7d94a4;
  border-bottom-color: #7d94a4;
}
.bg-black.navbar .nav > li.active > a {
  color: #fff;
  background-color: #181f24;
}
.bg-black .open > a,
.bg-black .open > a:hover,
.bg-black .open > a:focus {
  color: #fff;
}
.bg-black .text-muted {
  color: #627b8c !important;
}
.bg-black .text-lt {
  color: #c8d2d9 !important;
}
.bg-black .icon-muted {
  color: #627b8c !important;
}
.bg-black.auto .list-group-item,
.bg-black .auto .list-group-item {
  border-color: #191f23 !important;
  background-color: transparent;
}
.bg-black.auto .list-group-item:hover,
.bg-black.auto .list-group-item:focus,
.bg-black.auto .list-group-item:active,
.bg-black.auto .list-group-item.active,
.bg-black .auto .list-group-item:hover,
.bg-black .auto .list-group-item:focus,
.bg-black .auto .list-group-item:active,
.bg-black .auto .list-group-item.active {
  background-color: #181f24 !important;
}
.bg-primary {
  background-color: #545ca6;
  color: #dadcec;
}
.bg-primary.lt,
.bg-primary .lt {
  background-color: #666dad;
}
.bg-primary.lter,
.bg-primary .lter {
  background-color: #797fb4;
}
.bg-primary.dk,
.bg-primary .dk {
  background-color: #495098;
}
.bg-primary.dker,
.bg-primary .dker {
  background-color: #3e4589;
}
.bg-primary.bg,
.bg-primary .bg {
  background-color: #545ca6;
}
.bg-primary-ltest {
  background-color: #ecf6fb;
}
.bg-primary a {
  color: #fcfcfd;
}
.bg-primary a:hover {
  color: #fff;
}
.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
  background-color: inherit;
}
.bg-primary .nav > li:hover > a,
.bg-primary .nav > li:focus > a,
.bg-primary .nav > li:active > a,
.bg-primary .nav > li.active > a {
  color: #fff;
  background-color: #6068b3;
}
.bg-primary .nav > li > a {
  color: #ebecf5;
}
.bg-primary .nav > li > a:hover,
.bg-primary .nav > li > a:focus {
  background-color: #6068b3;
}
.bg-primary .nav .open > a {
  background-color: #6068b3;
}
.bg-primary .caret {
  border-top-color: #dadcec;
  border-bottom-color: #dadcec;
}
.bg-primary.navbar .nav > li.active > a {
  color: #fff;
  background-color: #495098;
}
.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
  color: #fff;
}
.bg-primary .text-muted {
  color: #b8bbdb !important;
}
.bg-primary .text-lt {
  color: #ffffff !important;
}
.bg-primary .icon-muted {
  color: #b8bbdb !important;
}
.bg-primary.auto .list-group-item,
.bg-primary .auto .list-group-item {
  border-color: #4b5395 !important;
  background-color: transparent;
}
.bg-primary.auto .list-group-item:hover,
.bg-primary.auto .list-group-item:focus,
.bg-primary.auto .list-group-item:active,
.bg-primary.auto .list-group-item.active,
.bg-primary .auto .list-group-item:hover,
.bg-primary .auto .list-group-item:focus,
.bg-primary .auto .list-group-item:active,
.bg-primary .auto .list-group-item.active {
  background-color: #495098 !important;
}
.bg-success {
  background-color: #1ab667;
  color: #a9f3ce;
}
.bg-success.lt,
.bg-success .lt {
  background-color: #20c974;
}
.bg-success.lter,
.bg-success .lter {
  background-color: #2ad980;
}
.bg-success.dk,
.bg-success .dk {
  background-color: #15a25a;
}
.bg-success.dker,
.bg-success .dker {
  background-color: #108d4e;
}
.bg-success.bg,
.bg-success .bg {
  background-color: #1ab667;
}
.bg-success-ltest {
  background-color: #f1ffed;
}
.bg-success a {
  color: #d6f9e7;
}
.bg-success a:hover {
  color: #fff;
}
.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
  background-color: inherit;
}
.bg-success .nav > li:hover > a,
.bg-success .nav > li:focus > a,
.bg-success .nav > li:active > a,
.bg-success .nav > li.active > a {
  color: #fff;
  background-color: #1acf74;
}
.bg-success .nav > li > a {
  color: #c0f6da;
}
.bg-success .nav > li > a:hover,
.bg-success .nav > li > a:focus {
  background-color: #1acf74;
}
.bg-success .nav .open > a {
  background-color: #1acf74;
}
.bg-success .caret {
  border-top-color: #a9f3ce;
  border-bottom-color: #a9f3ce;
}
.bg-success.navbar .nav > li.active > a {
  color: #fff;
  background-color: #15a25a;
}
.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
  color: #fff;
}
.bg-success .text-muted {
  color: #7decb4 !important;
}
.bg-success .text-lt {
  color: #ffffff !important;
}
.bg-success .icon-muted {
  color: #7decb4 !important;
}
.bg-success.auto .list-group-item,
.bg-success .auto .list-group-item {
  border-color: #17a05a !important;
  background-color: transparent;
}
.bg-success.auto .list-group-item:hover,
.bg-success.auto .list-group-item:focus,
.bg-success.auto .list-group-item:active,
.bg-success.auto .list-group-item.active,
.bg-success .auto .list-group-item:hover,
.bg-success .auto .list-group-item:focus,
.bg-success .auto .list-group-item:active,
.bg-success .auto .list-group-item.active {
  background-color: #15a25a !important;
}
.bg-info {
  background-color: #4cb6cb;
  color: #eaf6f9;
}
.bg-info.lt,
.bg-info .lt {
  background-color: #62bcce;
}
.bg-info.lter,
.bg-info .lter {
  background-color: #78c3d2;
}
.bg-info.dk,
.bg-info .dk {
  background-color: #36b0c8;
}
.bg-info.dker,
.bg-info .dker {
  background-color: #2ea0b6;
}
.bg-info.bg,
.bg-info .bg {
  background-color: #4cb6cb;
}
.bg-info-ltest {
  background-color: #ecfcff;
}
.bg-info a {
  color: #ffffff;
}
.bg-info a:hover {
  color: #fff;
}
.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
  background-color: inherit;
}
.bg-info .nav > li:hover > a,
.bg-info .nav > li:focus > a,
.bg-info .nav > li:active > a,
.bg-info .nav > li.active > a {
  color: #fff;
  background-color: #5dc0d3;
}
.bg-info .nav > li > a {
  color: #f2f2f2;
}
.bg-info .nav > li > a:hover,
.bg-info .nav > li > a:focus {
  background-color: #5dc0d3;
}
.bg-info .nav .open > a {
  background-color: #5dc0d3;
}
.bg-info .caret {
  border-top-color: #eaf6f9;
  border-bottom-color: #eaf6f9;
}
.bg-info.navbar .nav > li.active > a {
  color: #fff;
  background-color: #36b0c8;
}
.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
  color: #fff;
}
.bg-info .text-muted {
  color: #c3e6ed !important;
}
.bg-info .text-lt {
  color: #ffffff !important;
}
.bg-info .icon-muted {
  color: #c3e6ed !important;
}
.bg-info.auto .list-group-item,
.bg-info .auto .list-group-item {
  border-color: #39adc4 !important;
  background-color: transparent;
}
.bg-info.auto .list-group-item:hover,
.bg-info.auto .list-group-item:focus,
.bg-info.auto .list-group-item:active,
.bg-info.auto .list-group-item.active,
.bg-info .auto .list-group-item:hover,
.bg-info .auto .list-group-item:focus,
.bg-info .auto .list-group-item:active,
.bg-info .auto .list-group-item.active {
  background-color: #36b0c8 !important;
}
.bg-warning {
  background-color: #fad733;
  color: #fffefa;
}
.bg-warning.lt,
.bg-warning .lt {
  background-color: #f8da4e;
}
.bg-warning.lter,
.bg-warning .lter {
  background-color: #f7de69;
}
.bg-warning.dk,
.bg-warning .dk {
  background-color: #fcd417;
}
.bg-warning.dker,
.bg-warning .dker {
  background-color: #face00;
}
.bg-warning.bg,
.bg-warning .bg {
  background-color: #fad733;
}
.bg-warning-ltest {
  background-color: #fffee6;
}
.bg-warning a {
  color: #ffffff;
}
.bg-warning a:hover {
  color: #fff;
}
.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
  background-color: inherit;
}
.bg-warning .nav > li:hover > a,
.bg-warning .nav > li:focus > a,
.bg-warning .nav > li:active > a,
.bg-warning .nav > li.active > a {
  color: #fff;
  background-color: #fddd4a;
}
.bg-warning .nav > li > a {
  color: #f2f2f2;
}
.bg-warning .nav > li > a:hover,
.bg-warning .nav > li > a:focus {
  background-color: #fddd4a;
}
.bg-warning .nav .open > a {
  background-color: #fddd4a;
}
.bg-warning .caret {
  border-top-color: #fffefa;
  border-bottom-color: #fffefa;
}
.bg-warning.navbar .nav > li.active > a {
  color: #fff;
  background-color: #fcd417;
}
.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
  color: #fff;
}
.bg-warning .text-muted {
  color: #fef4c8 !important;
}
.bg-warning .text-lt {
  color: #ffffff !important;
}
.bg-warning .icon-muted {
  color: #fef4c8 !important;
}
.bg-warning.auto .list-group-item,
.bg-warning .auto .list-group-item {
  border-color: #f9d21a !important;
  background-color: transparent;
}
.bg-warning.auto .list-group-item:hover,
.bg-warning.auto .list-group-item:focus,
.bg-warning.auto .list-group-item:active,
.bg-warning.auto .list-group-item.active,
.bg-warning .auto .list-group-item:hover,
.bg-warning .auto .list-group-item:focus,
.bg-warning .auto .list-group-item:active,
.bg-warning .auto .list-group-item.active {
  background-color: #fcd417 !important;
}
.bg-danger {
  background-color: #f05050;
  color: #ffffff;
}
.bg-danger.lt,
.bg-danger .lt {
  background-color: #f06a6a;
}
.bg-danger.lter,
.bg-danger .lter {
  background-color: #f18282;
}
.bg-danger.dk,
.bg-danger .dk {
  background-color: #f13636;
}
.bg-danger.dker,
.bg-danger .dker {
  background-color: #f21b1b;
}
.bg-danger.bg,
.bg-danger .bg {
  background-color: #f05050;
}
.bg-danger-ltest {
  background-color: #fbedec;
}
.bg-danger a {
  color: #ffffff;
}
.bg-danger a:hover {
  color: #fff;
}
.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
  background-color: inherit;
}
.bg-danger .nav > li:hover > a,
.bg-danger .nav > li:focus > a,
.bg-danger .nav > li:active > a,
.bg-danger .nav > li.active > a {
  color: #fff;
  background-color: #f46565;
}
.bg-danger .nav > li > a {
  color: #f2f2f2;
}
.bg-danger .nav > li > a:hover,
.bg-danger .nav > li > a:focus {
  background-color: #f46565;
}
.bg-danger .nav .open > a {
  background-color: #f46565;
}
.bg-danger .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff;
}
.bg-danger.navbar .nav > li.active > a {
  color: #fff;
  background-color: #f13636;
}
.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
  color: #fff;
}
.bg-danger .text-muted {
  color: #e6e6e6 !important;
}
.bg-danger .text-lt {
  color: #ffffff !important;
}
.bg-danger .icon-muted {
  color: #e6e6e6 !important;
}
.bg-danger.auto .list-group-item,
.bg-danger .auto .list-group-item {
  border-color: #ee3939 !important;
  background-color: transparent;
}
.bg-danger.auto .list-group-item:hover,
.bg-danger.auto .list-group-item:focus,
.bg-danger.auto .list-group-item:active,
.bg-danger.auto .list-group-item.active,
.bg-danger .auto .list-group-item:hover,
.bg-danger .auto .list-group-item:focus,
.bg-danger .auto .list-group-item:active,
.bg-danger .auto .list-group-item.active {
  background-color: #f13636 !important;
}
.bg-white {
  background-color: #fff;
  color: #788188;
}
.bg-white a {
  color: #545a5f;
}
.bg-white a:hover {
  color: #3c4144;
}
.bg-white .text-muted {
  color: #939aa0 !important;
}
.bg-white-only {
  background-color: #fff;
}
.bg-empty {
  background-color: transparent;
}
a.bg-light:hover,
a.bg-light:focus {
  color: #545a5f;
}
.text-primary {
  color: #545ca6;
}
.text-primary-lt {
  color: #636bb0;
}
.text-primary-lter {
  color: #747bb9;
}
.text-primary-dk {
  color: #4b5395;
}
.text-primary-dker {
  color: #434984;
}
.text-info {
  color: #4cb6cb;
}
.text-info-lt {
  color: #60bed1;
}
.text-info-lter {
  color: #74c6d6;
}
.text-info-dk {
  color: #39adc4;
}
.text-info-dker {
  color: #339cb1;
}
.text-success {
  color: #1ab667;
}
.text-success-lt {
  color: #1dcc74;
}
.text-success-lter {
  color: #23e080;
}
.text-success-dk {
  color: #17a05a;
}
.text-success-dker {
  color: #14894e;
}
.text-warning {
  color: #fad733;
}
.text-warning-lt {
  color: #fbdc4c;
}
.text-warning-lter {
  color: #fbe165;
}
.text-warning-dk {
  color: #f9d21a;
}
.text-warning-dker {
  color: #f4ca06;
}
.text-danger {
  color: #f05050;
}
.text-danger-lt {
  color: #f26767;
}
.text-danger-lter {
  color: #f47f7f;
}
.text-danger-dk {
  color: #ee3939;
}
.text-danger-dker {
  color: #ec2121;
}
.text-dark {
  color: #5a6a7a;
}
.text-dark-lt {
  color: #657789;
}
.text-dark-lter {
  color: #718496;
}
.text-dark-dk {
  color: #4f5d6b;
}
.text-dark-dker {
  color: #44515d;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-muted {
  color: #939aa0;
}
.pos-rlt {
  position: relative;
}
.pos-stc {
  position: static;
}
.pos-abt {
  position: absolute;
}
.line {
  *width: 100%;
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
}
.line-xs {
  margin: 0;
}
.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}
.line-dashed {
  border-style: dashed !important;
  background-color: transparent;
  border-width: 0;
}
.no-line {
  border-width: 0;
}
.no-border,
.no-borders {
  border-color: transparent;
  border-width: 0;
}
.no-radius {
  border-radius: 0;
}
.block {
  display: block;
}
.block.hide {
  display: none;
}
.inline {
  display: inline-block !important;
}
.none {
  display: none;
}
.pull-right-lg {
  float: right;
}
.pull-none {
  float: none;
}
.rounded {
  border-radius: 500px;
}
.btn-s-xs {
  min-width: 90px;
}
.btn-s-sm {
  min-width: 100px;
}
.btn-s-md {
  min-width: 120px;
}
.btn-s-lg {
  min-width: 150px;
}
.btn-s-xl {
  min-width: 200px;
}
.l-h-2x {
  line-height: 2em;
}
.l-h-1x {
  line-height: 1.2;
}
.l-h {
  line-height: 1.5;
}
.v-middle {
  vertical-align: middle !important;
}
.v-top {
  vertical-align: top !important;
}
.v-bottom {
  vertical-align: bottom !important;
}
.font-normal {
  font-weight: normal;
}
.font-thin {
  font-weight: 300;
}
.font-bold {
  font-weight: 700;
}
.text-lg {
  font-size: 18px;
}
.text-md {
  font-size: 16px;
}
.text-sm {
  font-size: 13px;
}
.text-xs {
  font-size: 12px;
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-u-c {
  text-transform: uppercase;
}
.text-l-t {
  text-decoration: line-through;
}
.text-u-l {
  text-decoration: underline;
}
.text-active,
.active > .text,
.active > .auto .text {
  display: none !important;
}
.active > .text-active,
.active > .auto .text-active {
  display: inline-block !important;
}
.box-shadow {
  box-shadow: 0 2px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05);
}
.wrapper-xs {
  padding: 5px;
}
.wrapper-sm {
  padding: 10px;
}
.wrapper {
  padding: 15px;
}
.wrapper-md {
  padding: 20px;
}
.wrapper-lg {
  padding: 30px;
}
.wrapper-xl {
  padding: 50px;
}
.padder-lg {
  padding-left: 30px;
  padding-right: 30px;
}
.padder-md {
  padding-left: 20px;
  padding-right: 20px;
}
.padder {
  padding-left: 15px;
  padding-right: 15px;
}
.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}
.no-padder {
  padding: 0 !important;
}
.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}
.pull-out {
  margin: -10px -15px;
}
.b {
  border: 1px solid rgba(0,0,0,0.05);
}
.b-a {
  border: 1px solid #eaeef1;
}
.b-t {
  border-top: 1px solid #eaeef1;
}
.b-r {
  border-right: 1px solid #eaeef1;
}
.b-b {
  border-bottom: 1px solid #eaeef1;
}
.b-l {
  border-left: 1px solid #eaeef1;
}
.b-light {
  border-color: #d9e4e6;
}
.b-dark {
  border-color: #657789;
}
.b-primary {
  border-color: #636bb0;
}
.b-success {
  border-color: #1dcc74;
}
.b-info {
  border-color: #60bed1;
}
.b-warning {
  border-color: #fbdc4c;
}
.b-danger {
  border-color: #f26767;
}
.b-black {
  border-color: #2e3941;
}
.b-white {
  border-color: #fff;
}
.b-dashed {
  border-style: dashed !important;
}
.b-2x {
  border-width: 2px;
}
.b-3x {
  border-width: 3px;
}
.b-4x {
  border-width: 4px;
}
.b-5x {
  border-width: 5px;
}
.r {
  border-radius: 2px 2px 2px 2px;
}
.r-2x {
  border-radius: 4px;
}
.r-3x {
  border-radius: 6px;
}
.r-l {
  border-radius: 2px 0 0 2px;
}
.r-r {
  border-radius: 0 2px 2px 0;
}
.r-t {
  border-radius: 2px 2px 0 0;
}
.r-b {
  border-radius: 0 0 2px 2px;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.media-xs {
  min-width: 50px;
}
.media-sm {
  min-width: 80px;
}
.media-md {
  min-width: 90px;
}
.media-lg {
  min-width: 120px;
}
.avatar {
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;
}
.avatar img {
  border-radius: 500px;
  width: 100%;
}
.avatar i {
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
}
.avatar i.md {
  width: 12px;
  height: 12px;
  margin: 1px;
}
.avatar i.sm {
  margin: -1px;
}
.avatar i.right {
  left: auto;
  right: 0;
}
.avatar i.bottom {
  left: auto;
  top: auto;
  bottom: 0;
  right: 0;
}
.avatar i.on {
  background-color: #1ab667;
}
.avatar i.off {
  background-color: #939aa0;
}
.avatar i.busy {
  background-color: #f05050;
}
.avatar i.away {
  background-color: #fad733;
}
.thumb-lg {
  width: 128px;
  display: inline-block;
}
.thumb-md {
  width: 64px;
  display: inline-block;
}
.thumb {
  width: 50px;
  display: inline-block;
}
.thumb-sm {
  width: 40px;
  display: inline-block;
}
.thumb-xs {
  width: 30px;
  display: inline-block;
}
.thumb-wrapper {
  padding: 2px;
  border: 1px solid #dbe2e7;
}
.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}
.img-full {
  width: 100%;
}
.img-full img {
  width: 100%;
}
.clear {
  display: block;
  overflow: hidden;
}
.row-sm {
  margin-left: -10px;
  margin-right: -10px;
}
.row-sm > div {
  padding-left: 10px;
  padding-right: 10px;
}
.i-checks input {
  opacity: 0;
}
.i-checks input:checked + i {
  border-color: #545ca6;
}
.i-checks input:checked + i:before {
  position: absolute;
  left: 0px;
  width: 100%;
  top: 2px;
  text-align: center;
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  color: #545ca6;
}
.i-checks input[type="radio"] + i {
  border-radius: 100%;
}
.i-checks input[type="checkbox"]:checked + i:before {
  content: "\f00c";
}
.i-checks input[type="radio"]:checked + i:before {
  content: "\f111";
}
.i-checks input[disabled] + i,
fieldset[disabled] .i-checks input + i {
  border-color: #dbe2e7;
}
.i-checks input[disabled] + i:before,
fieldset[disabled] .i-checks input + i:before {
  color: #cbd5dd;
}
.i-checks i {
  width: 18px;
  height: 18px;
  line-height: 1;
  border: 1px solid #cbd5dd;
  background-color: #fff;
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  position: relative;
  font-size: 12px;
}
.ie8 .i-checks i {
  display: none;
}
.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.scroll-y {
  overflow-y: auto;
}
.scroll-x {
  overflow-x: auto;
}
.no-touch .scroll-x,
.no-touch .scroll-y {
  overflow: hidden;
}
.no-touch .scroll-x:hover,
.no-touch .scroll-x:focus,
.no-touch .scroll-x:active {
  overflow-x: auto;
}
.no-touch .scroll-y:hover,
.no-touch .scroll-y:focus,
.no-touch .scroll-y:active {
  overflow-y: auto;
}
.no-touch .hover-action {
  display: none;
}
.no-touch .hover:hover .hover-action {
  display: inherit;
}
.hover-rotate {
  -webkit-transition: all .2s ease-in-out .1s;
  transition: all .2s ease-in-out .1s;
}
.hover:hover .hover-rotate,
.hover:active .hover-rotate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.item .top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.item .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.item .center {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.item .gd {
  background-color: transparent;
  background-image: -webkit-gradient(linear,0 0,0 100%,from(rgba(0,0,0,0)),color-stop(20%,rgba(0,0,0,0.25)),to(rgba(0,0,0,0.75)));
  background-image: -webkit-linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.25) 20%,rgba(0,0,0,0.75));
  background-image: -moz-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.25) 20%,rgba(0,0,0,0.75));
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.25) 20%,rgba(0,0,0,0.75));
  background-repeat: no-repeat;
}
.item .opacity {
  background-color: rgba(0,0,0,0.75);
}
.item-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.item-overlay.active,
.item:hover .item-overlay {
  display: block;
}
.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
}
.backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.backdrop.in {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.h {
  font-size: 170px;
  text-shadow: 0 1px 0 #d9d9d9, 0 2px 0 #d0d0d0, 0 5px 10px rgba(0,0,0,0.125), 0 10px 20px rgba(0,0,0,0.2);
}
@media screen and (min-width: 992px) {
  .col-lg-2-4 {
    width: 20.000%;
    float: left;
  }
}
@media (max-width: 767px) {
  .shift {
    display: none !important;
  }
  .shift.in {
    display: block !important;
  }
  .row-2 [class*="col"] {
    width: 50%;
    float: left;
  }
  .row-2 .col-0 {
    clear: none;
  }
  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }
  .text-center-xs {
    text-align: center;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-right-xs {
    text-align: right;
  }
  .pull-none-xs {
    float: none !important;
  }
  .dropdown-menu.pull-none-xs {
    left: 0;
  }
  .hidden-xs.show {
    display: inherit !important;
  }
  .wrapper-lg {
    padding: 15px;
  }
  .padder-lg {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.musicbar {
  position: relative;
}
.musicbar span {
  position: absolute;
  bottom: 0;
  width: 15%;
  text-indent: -9999px;
}
.bar1 {
  left: 0%;
  height: 60%;
}
.bar2 {
  left: 20%;
  height: 45%;
}
.bar3 {
  left: 40%;
  height: 90%;
}
.bar4 {
  left: 60%;
  height: 60%;
}
.bar5 {
  left: 80%;
  height: 40%;
}
.animate .a1 {
  -webkit-animation: movingbar1 1s infinite alternate both;
  -moz-animation: movingbar1 1s infinite alternate both;
  animation: movingbar1 1s infinite alternate both;
}
.animate .a2 {
  -webkit-animation: movingbar2 1.5s infinite alternate both;
  -moz-animation: movingbar2 1.5s infinite alternate both;
  animation: movingbar2 1.5s infinite alternate both;
}
.animate .a3 {
  -webkit-animation: movingbar3 1.2s infinite alternate both;
  -moz-animation: movingbar3 1.2s infinite alternate both;
  animation: movingbar3 1.2s infinite alternate both;
}
.animate .a4 {
  -webkit-animation: movingbar4 1.1s infinite alternate both;
  -moz-animation: movingbar4 1.1s infinite alternate both;
  animation: movingbar4 1.1s infinite alternate both;
}
.animate .a5 {
  -webkit-animation: movingbar5 1.2s infinite alternate both;
  -moz-animation: movingbar5 1.2s infinite alternate both;
  animation: movingbar5 1.2s infinite alternate both;
}
@-webkit-keyframes movingbar1 {
  0% {
    height: 32%;
  }
  25% {
    height: 80%;
  }
  50% {
    height: 56%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 84%;
  }
}
@-moz-keyframes movingbar1 {
  0% {
    height: 32%;
  }
  25% {
    height: 80%;
  }
  50% {
    height: 56%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 84%;
  }
}
@keyframes movingbar1 {
  0% {
    height: 32%;
  }
  25% {
    height: 80%;
  }
  50% {
    height: 56%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 84%;
  }
}
@-webkit-keyframes movingbar2 {
  0% {
    height: 20%;
  }
  25% {
    height: 58%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 74%;
  }
  100% {
    height: 53%;
  }
}
@-moz-keyframes movingbar2 {
  0% {
    height: 20%;
  }
  25% {
    height: 58%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 74%;
  }
  100% {
    height: 53%;
  }
}
@keyframes movingbar2 {
  0% {
    height: 20%;
  }
  25% {
    height: 58%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 74%;
  }
  100% {
    height: 53%;
  }
}
@-webkit-keyframes movingbar3 {
  0% {
    height: 34%;
  }
  25% {
    height: 69%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 41%;
  }
}
@-moz-keyframes movingbar3 {
  0% {
    height: 34%;
  }
  25% {
    height: 69%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 41%;
  }
}
@keyframes movingbar3 {
  0% {
    height: 34%;
  }
  25% {
    height: 69%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 41%;
  }
}
@-webkit-keyframes movingbar4 {
  0% {
    height: 80%;
  }
  25% {
    height: 49%;
  }
  50% {
    height: 59%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 65%;
  }
}
@-moz-keyframes movingbar4 {
  0% {
    height: 80%;
  }
  25% {
    height: 49%;
  }
  50% {
    height: 59%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 65%;
  }
}
@keyframes movingbar4 {
  0% {
    height: 80%;
  }
  25% {
    height: 49%;
  }
  50% {
    height: 59%;
  }
  75% {
    height: 82%;
  }
  100% {
    height: 65%;
  }
}
@-webkit-keyframes movingbar5 {
  0% {
    height: 50%;
  }
  25% {
    height: 30%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 50%;
  }
  100% {
    height: 65%;
  }
}
@-moz-keyframes movingbar5 {
  0% {
    height: 50%;
  }
  25% {
    height: 30%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 50%;
  }
  100% {
    height: 65%;
  }
}
@keyframes movingbar5 {
  0% {
    height: 50%;
  }
  25% {
    height: 30%;
  }
  50% {
    height: 90%;
  }
  75% {
    height: 50%;
  }
  100% {
    height: 65%;
  }
}
